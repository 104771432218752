












































































































































import Vue from "vue";
import type { PropType } from "vue";
import firestore from "firebase";
import ModalForm from "./ModalForm.vue";
import QuantityModal from "./QuantityModal.vue";
import CardSetModal from "./CardSetModal.vue";
import {
  onEditInventoryPageChange,
  DbToRowConverter,
  updateCard,
  deleteRows,
  getUploadDoc,
  getCount,
  markVerified,
  CardInfo,
  TableRow,
  Card,
} from "@/helpers/common";

export default Vue.extend({
  props: {
    editable: Boolean,
    disButton: Boolean,
    ifdisable: Boolean,
    checkable: Boolean,
    dbRef: Object as PropType<firestore.firestore.CollectionReference>,
    storeId: String,
  },
  name: "InventoryTable",
  components: {
    ModalForm,
    QuantityModal,
    CardSetModal,
  },
  data() {
    return {
      isComponentModalActive: false,
      isQuantityModalActive: false,
      isCardSetModalActive: false,
      checkboxPosition: "left",
      checkedRows: [],
      type: null,
      qVal: {},
      newVal: {},
      CardVal: {},
      checkLength: "",
      unsubscribeSnapshot: null as (() => void) | null,
      unsubSizeSnapshot: null as (() => void) | null,
      unsubCountSnapshot: null as (() => void) | null,
      currentPage: 1,
      firstDoc: null,
      lastDoc: null,
      inventoryData: [] as Card[],
      sortOrder: "asc",
      sortField: "displayName",
      numRows: 7,
      totalSize: 0,
      counterId: "" as string,
      verifiedCount: 0,
      checkUpload: true,
      paginated: false,
      uploadVerified: false,
    };
  },
  computed: {
    loading: function (): boolean {
      if (!this.$props.editable) {
        return this.$props.dbRef == null ? true : false;
      }
      if (this.$props.dbRef == null) {
        return false;
      }
      this.doneVerification();
      return !this.uploadVerified;
    },
  },
  methods: {
    doneVerification(): void {
      if (!this.$data.uploadVerified) {
        return;
      }
      this.$data.paginated = true;
      if (this.$data.unsubCountSnapshot) {
        this.$data.unsubCountSnapshot();
        this.$data.unsubCountSnapshot = null;
      }
    },
    updateConditionCol(value: Card) {
      this.closeModal({
        uploadedDbId: value.uploadedDbId,
        card: value,
      });
    },
    closeModal(updatedCardInfo: CardInfo) {
      this.$data.isComponentModalActive = false;
      this.$data.isQuantityModalActive = false;
      this.$data.isCardSetModalActive = false;
      if (updatedCardInfo == null) {
        // Modal was closed without any update
        return;
      }
      updateCard(updatedCardInfo, this.$props.dbRef, this.$props.storeId);
    },
    handleCardPopup(row: TableRow) {
      this.$data.isCardSetModalActive = true;
      this.$data.CardVal = row;
    },

    //Buy Price Popup
    handlePopup(row: TableRow) {
      this.$data.isComponentModalActive = true;
      this.$data.newVal = row;
    },
    //Quantity Popup
    handleQuantity(row: TableRow) {
      this.$data.isQuantityModalActive = true;
      this.$data.qVal = row;
    },
    runOnPageChange(nextPage: number) {
      console.log("Running page change");
      if (this.$data.unsubscribeSnapshot) {
        this.$data.unsubscribeSnapshot();
      }
      this.$data.unsubscribeSnapshot = onEditInventoryPageChange(
        this.$props.dbRef,
        this.$data.currentPage,
        nextPage,
        this.$data.sortField,
        this.sortOrder,
        this.firstDoc,
        this.lastDoc,
        this.dataCallback,
        this.numRows
      );
      this.currentPage = nextPage;
    },
    dataCallback(documentSnapshots: { docs: any[] }) {
      this.inventoryData = [];
      this.firstDoc = documentSnapshots.docs[0];
      this.lastDoc = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      documentSnapshots.docs.forEach(
        (element: firestore.firestore.QueryDocumentSnapshot) => {
          this.inventoryData.push(DbToRowConverter(element));
        }
      );
    },
    uploadCallback(documentSnapshot: any) {
      this.totalSize = documentSnapshot.get("totalCards");
      this.uploadVerified = documentSnapshot.get("uploadVerified");
      if (!this.counterId && documentSnapshot.get("counterId")) {
        this.counterId = documentSnapshot.get("counterId");
        console.log("CounterId set", this.counterId);
        this.unsubCountSnapshot = getCount(
          this.counterId,
          this.watchUploadCount
        );
      }
    },
    onSort(field: string, order: string) {
      // Sort according to field and order
      this.sortOrder = order;
      if (field == "matched") {
        this.sortField = field;
      } else {
        // User display version of Name or Set
        this.sortField =
          "display" + field.charAt(0).toUpperCase() + field.slice(1);
      }
      this.reset();
    },
    reset(): void {
      if (this.unsubSizeSnapshot) {
        this.unsubSizeSnapshot();
      }
      if (this.unsubCountSnapshot) {
        this.unsubCountSnapshot();
        this.unsubCountSnapshot = null;
      }
      if (this.unsubscribeSnapshot) {
        this.unsubscribeSnapshot();
        this.unsubscribeSnapshot = null;
      }
      this.firstDoc = null;
      this.lastDoc = null;
      this.currentPage = 1;
      this.counterId = "";
      this.verifiedCount = 0;
      this.loading = false;
      this.checkUpload = true;
      this.runOnPageChange(this.currentPage);
      this.unsubSizeSnapshot = getUploadDoc(
        this.$props.dbRef.parent,
        this.uploadCallback
      );
    },
    deleteRows() {
      deleteRows(this.checkedRows, this.$props.dbRef, this.totalSize);
    },
    watchUploadCount(snapshot: firestore.firestore.QuerySnapshot) {
      let uploaded_count = 0;
      snapshot.forEach((doc) => {
        uploaded_count += doc.data().count;
      });
      this.verifiedCount = uploaded_count;
      if (this.verifiedCount == this.totalSize) {
        markVerified(this.$props.dbRef);
      }
    },
  },
  watch: {
    // It listens to the change in prop name
    dbRef: function (): void {
      this.reset();
    },
  },
});
