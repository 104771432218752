

























































import { Vue } from "vue-property-decorator";
import SearchBar from "@/components/SearchBar.vue";
import Card from "@/components/Card.vue";
import { SearchResults } from "@/helpers/common";

export default Vue.extend({
  name: "SearchPage",
  components: {
    SearchBar,
    Card,
  },
  data() {
    return {
      expandOnHover: false,
      expandWithDelay: false,
      mobile: "reduce",
      reduce: false,
      cartQunatity: "",
      searchData: [] as SearchResults[],
    };
  },
  methods: {
    searchCardItem(searchResult: SearchResults[]) {
      // when search item
      this.searchData = searchResult;
    },
  },
});
