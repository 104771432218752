
































































































































































































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      type: null,
      hideCCDetails: false,
      cashSwitch: false,
      paypalSwitch: false,
      name: "",
      phn: "",
      makePayment: false,
      data: [
        {
          id: 1,
          type: "Initial Inventory Count",
          sku: "B01HRKGYTC",
          name: "Dell Ultrasharp 25 Screen",
          location: "Central DC",
          quantity: "400",
          "inv-change": "+200",
          "time-date": "13:43:27 2016-10-15 ",
        },
        {
          id: 2,
          type: "Initial Inventory Count",
          sku: "B01HRKGYTY",
          name: "Dell Ultrasharp 25 Screen",
          location: "North Warehouse",
          quantity: "400",
          "inv-change": "+200",
          "time-date": "13:43:27 2016-10-15 ",
        },
        {
          id: 3,
          type: "Receive to Inventory",
          sku: "B01HRKGYTC",
          name: "Dell Ultrasharp 25 Screen",
          location: "Central DC",
          quantity: "400",
          "inv-change": "+200",
          "time-date": "13:43:27 2016-10-15 ",
        },
        {
          id: 4,
          type: "Initial Inventory Count",
          sku: "B01HRKGYTC",
          name: "Dell Ultrasharp 25 Screen",
          location: "Central DC",
          quantity: "400",
          "inv-change": "+200",
          "time-date": "13:43:27 2016-10-15 ",
        },
      ],
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          searchable: true,
          field: "type",
          label: "Type",
        },
        {
          field: "sku",
          label: "SKU",
        },
        {
          field: "name",
          label: "Name",
        },
        {
          field: "location",
          label: "Location",
        },
        {
          field: "quantity",
          label: "Quantity",
        },
        {
          field: "inv-change",
          label: "Inv. Change",
        },
        {
          field: "time-date",
          label: "Time & Date",
        },
      ],
    };
  },
});
