





























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class VendorSignUp extends Vue {
  isAnimated = true;
  rounded = true;
  hasNavigation = true;
  prevIcon = "chevron-left";
  nextIcon = "chevron-right";
  labelPosition = "bottom";

  get vendorEmail() {
    return this.$store.state.vendorEmail;
  }

  set vendorEmail(value: string) {
    this.$store.commit("updateVendorEmail", value);
  }
}
