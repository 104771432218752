












































































import Vue from "vue";
import type { PropType } from "vue";
import { DisplayCard, saveCartItems } from "../helpers/common";

export default Vue.extend({
  props: {
    item: Object as PropType<DisplayCard>,
    handleQuantity1: Function,
  },

  data() {
    return {
      isCardModalActive: false,
      cartQunatity: 0,
      quantityToAdd: 1,
    };
  },
  methods: {
    handleDetail() {
      this.isCardModalActive = true;
    },
    handleQuantity(itemToAdd: DisplayCard, type: string | null) {
      let cartQuantity = 1;
      if (type && type == "fromDetailsModel") {
        //if user is adding from detail model page
        cartQuantity = this.quantityToAdd;
      }
      let matched = false;
      const cartItems = this.$store.state.cart as DisplayCard[];
      cartItems.some((element) => {
        if (element.id === itemToAdd.id) {
          element.quantity += cartQuantity;
          return (matched = true);
        }
      });
      if (!matched) {
        cartItems.push({ ...itemToAdd, quantity: cartQuantity });
      }
      saveCartItems(cartItems);
      this.$store.commit("setCart", cartItems);
      this.isCardModalActive = false;
    },
  },
});
