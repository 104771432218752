



















































































import { Vue } from "vue-property-decorator";
import { DisplayCard, removeCartItem, updateQuantity } from "../helpers/common";

export default Vue.extend({
  data() {
    return {
      total: 10,
      current: 10,
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      hasInput: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      inputPosition: "",
      inputDebounce: "",
    };
  },
  methods: {
    trashItems(itemToRemove: DisplayCard) {
      removeCartItem(this.$store, this.$store.state.cart, itemToRemove);
    },
    updateQuantity(itemToUpdate: DisplayCard, quantity: number) {
      updateQuantity(
        this.$store,
        this.$store.state.cart,
        itemToUpdate,
        quantity
      );
    },
  },
});
