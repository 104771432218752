




























































































































































































import Vue from "vue";
import InventoryTable from "@/components/InventoryTable.vue";
import { getLiveCollection } from "@/helpers/common";
export default Vue.extend({
  name: "VendorEdit",
  components: {
    InventoryTable,
  },
  data() {
    return {
      readOnly: true,
      isComponentModalActive: false,
      disable: true,
      cardsRef: {},
      storeId: "",
      paypalSwitch: false,
      hideCCDetails: false,
      cashSwitch: false,
      data: [],
      address: "",
      name: "",
      phn: "",
      columns: null,
    };
  },
  mounted() {
    this.storeId = this.$store.state.uid;
    const Promise = getLiveCollection(this.storeId);
    Promise.then((querySnapshot) => {
      if (querySnapshot.empty) {
        return;
      }
      this.cardsRef = querySnapshot.docs[0].ref.collection("liveCards");
    });
  },
});
