



















import Vue from "vue";
import type { PropType } from "vue";
import { CardInfo, Card } from "../helpers/common";

export default Vue.extend({
  name: "QuantityModal",
  props: {
    newVal: Object as PropType<Card>,
    isQuantityModalActive: Boolean,
  },
  data() {
    return {
      value: 0,
    };
  },
  mounted() {
    const newVal = this.newVal.quantity;
    if (newVal != null) {
      this.value = newVal;
    }
  },
  methods: {
    showVal() {
      const newVal = this.newVal.quantity;
      if (newVal != null) {
        this.value = newVal;
      }
    },
    updateBtn() {
      if (this.newVal.quantity == this.value) {
        this.cleanUp(null);
        return;
      }
      this.newVal.quantity = this.value;
      const { uploadedDbId: _, ...updatedCard } = this.newVal;
      const uploadDbId = this.newVal.uploadedDbId;
      if (uploadDbId == null) {
        return;
      }
      this.cleanUp({
        uploadedDbId: uploadDbId,
        card: updatedCard,
      });
    },
    cleanUp(updatedCardInfo: CardInfo | null) {
      this.value = 0;
      this.$emit("close", updatedCardInfo);
    },
  },
});
