<template>
  <div class="inner-page">
    <div class="signInPg">
      <div>Please sign in!</div>
      <div id="firebaseui-auth-container"></div>
      <div>
        First time here? Signing in will automatically create a new account
      </div>
    </div>
  </div>
</template>

<script>
import { uiConfig, ui } from "../firebase";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "FirebaseAuthentication",
  created() {
    this.$store.commit("setVind", this.$route.params.vind);
  },
  mounted() {
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>
<style scoped>
.signInPg {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
