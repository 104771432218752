








































































































import { Component, Vue } from "vue-property-decorator";
import { db } from "../firebase";

@Component
export default class VendorAccess extends Vue {
  email = "";
  phoneNumber = "";
  emailStatus = "";
  emailMessage = "";

  alert() {
    if (this.email) {
      this.emailStatus = "";
      this.emailMessage = "";
      this.$buefy.dialog.alert({
        message:
          "Thank you for submitting your access request! Please allow a team member 2-3 days to respond to your request.",
        onConfirm: this.sendMail,
      });
    } else {
      this.emailStatus = "is-danger";
      this.emailMessage = "Email required";
    }
  }

  sendMail() {
    // Add request to firestore
    db.collection("vendorRequests")
      .doc(this.email)
      .set({
        email: this.email,
        phoneNumber: this.phoneNumber,
      })
      .then(() => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    // Send details email to user

    // Send request email to team

    // redirect to home page
    this.$router.push("/");
  }
}
