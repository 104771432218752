






import { Vue } from "vue-property-decorator";
import SearchBar from "@/components/SearchBar.vue"; // @ is an alias to /src

export default Vue.extend({
  components: {
    SearchBar,
  },
});
