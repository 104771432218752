


















































































import Vue from "vue";
import { CollectionReference } from "@firebase/firestore-types";
import InventoryTable from "@/components/InventoryTable.vue";
import Papa, { ParseConfig } from "papaparse";
import { func } from "../firebase";
import {
  checkForDups,
  uploadData,
  getDrafts,
  allVerified,
  Draft,
} from "@/helpers/common";

interface FileData {
  buy_price: number;
  buy_qty: number;
  card: string;
  condiition: string;
  game: string;
  id: number;
  match: boolean;
  product_id: number;
  sell_qty: number;
  set: string;
}

// type PapaResult = {
//   data: any;
//   errors: {
//     type: string;
//     code: string;
//     message: string;
//     row: number;
//   };
//   meta: Record<string, unknown>;
// };

export default Vue.extend({
  name: "InventoryEdit",
  components: {
    InventoryTable,
  },
  data() {
    return {
      name: "",
      isdeleteActive: false,
      Checkable: true,
      readOnly: false,
      disable: false,
      indeterminate: false,
      type: "is-primary",
      size: "is-medium",
      showValue: true,
      format: "raw",
      precision: 2,
      keepTrailingZeroes: false,
      locale: undefined,
      popUpValue: "",
      fileData: [] as FileData[],
      isPaginated: true,
      perPage: 20,
      file: "",
      processing: false,
      popUpLabel: "",
      papaConfig: {
        worker: false,
        header: true,
        skipEmptyLines: "greedy",
        complete: (this as any).onComplete,
        step: (this as any).papaStep,
      } as ParseConfig,
      loading: false,
      counter: 0,
      storeId: "",
      cardsRef: {} as CollectionReference,
      drafts: [] as Draft[],
    };
  },

  methods: {
    async submitUpload(): Promise<void> {
      // If not storeId or ref to cards table, skip
      if (!this.storeId || !this.cardsRef) {
        return;
      }
      // Check if all cards are verified
      const areAllVerified = await allVerified(this.cardsRef);
      if (areAllVerified) {
        // All cards are verified, go live with data
        const goLive = func.httpsCallable("goLive");
        goLive({
          storeId: this.storeId,
          uploadRef: this.cardsRef.path,
        }).then(() => {
          console.log("Upload complete!");
        });
      } else {
        // Some cards still need verification
        // Display toast with message and auto sort table so that unverified records are shown at the top
        this.$buefy.toast.open("Please verify all records!");
        (this.$refs.inventoryTable as any).onSort("matched", "asc");
      }
    },

    selectDraft(val: Draft): void {
      console.log(val.ref.path);
      this.cardsRef = val.ref.collection("uploadedCards");
    },

    getFile(): void {
      this.loading = true;
      console.time("Start parse");
      Papa.parse(this.file, this.papaConfig);
    },

    onComplete(): void {
      console.log("Done");
      this.fileData.sort((a: any, b: any) => {
        let cardA = a.card.toUpperCase() + "~" + a.set.toUpperCase();
        let cardB = b.card.toUpperCase() + "~" + b.set.toUpperCase();
        if (cardA < cardB) {
          return -1;
        }
        if (cardA > cardB) {
          return 1;
        }
        return 0;
      });
      this.loading = false;
      console.timeEnd("Start parse");
      let { dupsFound, fileData } = checkForDups(this.fileData);
      this.fileData = fileData;
      if (dupsFound) {
        this.$buefy.dialog.alert({
          message: "Some duplicate entries were found and removed",
        });
      }
      uploadData(this.storeId, this.fileData).then((uploadedCardsRef) => {
        this.cardsRef = uploadedCardsRef;
      });
    },

    async papaStep(results: any): Promise<void> {
      if (results !== undefined) {
        results.data.id = this.counter + 1;
        this.counter += 1;
        // Setup buyListData object which will be added to DB
        let buyListData: any = [];
        for (let col in results.data) {
          if (results.data[col].length > 0 && !isNaN(results.data[col])) {
            results.data[col] = +results.data[col];
          }
          let updatedCol = col.toLowerCase().split(" ").join("_");
          buyListData[updatedCol] = results.data[col];
        }
        buyListData["match"] = true;
        this.fileData.push(buyListData);
      }
    },
  },
  mounted(): void {
    this.storeId = this.$store.state.uid;
    getDrafts(this.storeId).then((docs) => {
      this.file = "";
      if (docs.length > 0) {
        this.$buefy.toast.open("Drafts available!");
      }
      this.drafts = docs;
    });
  },
});
