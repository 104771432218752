






















































import { Component, Vue } from "vue-property-decorator";
import { func } from "../firebase";
import debounce from "lodash/debounce";
import Card from "@/components/Card.vue"; // @ is an alias to /src

interface cardInfo {
  id: string;
  title: string;
  imgSrc: string;
  description: string;
  price: number;
}

@Component({
  components: {
    Card,
  },
})
export default class Search extends Vue {
  data: cardInfo[] = [];
  isScrollable = false;
  maxHeight = 200;
  searchText = "";
  currentRoute = null;
  currentMenu = { icon: "account-group", text: "All" };
  menus = [
    { icon: "account-group", text: "Card" },
    { icon: "account-group", text: "Sets" },
  ];
  isFetching = false;
  ncolumns = 5;
  searchEsDebounce = debounce(this.searchEs, 500);

  searchEs(card: string): void {
    if (!card) {
      this.data = [];
      this.$emit("search-item", this.data);
      return;
    }
    this.isFetching = true;
    const searchCards = func.httpsCallable("searchCards");
    searchCards({ card: card }).then((result) => {
      const cards = result.data.cards;
      if (cards.length > 0) {
        this.data = [];
      }
      for (var res of cards) {
        this.data.push(res);
      }
      this.isFetching = false;
      this.$emit("search-item", this.data); // emit event to parent
    });
  }

  searchPage(): void {
    if (this.$route.name == "Home") {
      // user coming from home page
      if (this.searchText) {
        this.$router.push("/search?find=" + this.searchText); // add the search string in url as query parameter
      } else {
        this.$buefy.toast.open("Please enter a card or set name");
      }
    }
  }

  mounted(): void {
    if (this.$route.query && this.$route.query.find) {
      // when user mount on this page check if he is coming from home
      const query = this.$route.query.find ? this.$route.query.find : "";
      if (query instanceof Array && query[0]) {
        this.searchText = query[0];
        this.searchEs(this.searchText);
      } else if (typeof query === "string") {
        this.searchText = query;
        this.searchEs(this.searchText);
      }
    }
  }
}
