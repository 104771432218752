









































import Vue from "vue";
import type { PropType } from "vue";
import SearchBar from "./SearchBar.vue";
import CardSlider from "./CardSlider.vue";
import { CardInfo, SearchResults, Card } from "../helpers/common";

export default Vue.extend({
  name: "CardSetModal",
  components: {
    CardSlider,
    SearchBar,
  },
  props: {
    isCardSetModalActive: Boolean,
    cardV: Object as PropType<Card>,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      useAlias: true,
      setData: {},
      cardData: "",
      searchData: [] as SearchResults[],
      selectedCardIndex: 0,
    };
  },
  methods: {
    searchCardItem(searchResults: SearchResults[]) {
      if (searchResults.length < 0) {
        return;
      }
      this.searchData = searchResults;
    },
    updateCmodal() {
      if (this.searchData.length < 0) {
        return;
      }

      if (
        this.selectedCardIndex < 0 ||
        this.selectedCardIndex > this.searchData.length
      ) {
        return;
      }

      const updatedCardInfo: CardInfo = {
        useAlias: this.useAlias,
        uploadedDbId: this.cardV.uploadedDbId ? this.cardV.uploadedDbId : "",
        aliasDbId: this.searchData[this.selectedCardIndex].id,
        card: {
          ...this.cardV,
          nameAlias: this.searchData[this.selectedCardIndex].name,
          displayName: this.searchData[this.selectedCardIndex].name,
          setAlias: this.searchData[this.selectedCardIndex].set,
          displaySet: this.searchData[this.selectedCardIndex].set,
          rarityAlias: this.searchData[this.selectedCardIndex].rarity,
          displayRarity: this.searchData[this.selectedCardIndex].rarity,
          numberAlias: this.searchData[this.selectedCardIndex].number,
          displayNumber: this.searchData[this.selectedCardIndex].number,
          matched: true,
        },
      };
      delete updatedCardInfo.card.image;
      delete updatedCardInfo.card.dbId;
      this.clearModal(updatedCardInfo);
    },
    closeCmodal() {
      this.clearModal(null);
    },
    clearModal(updatedCardInfo: CardInfo | null) {
      this.useAlias = true;
      this.setData = {};
      this.cardData = "";
      this.selectedCardIndex = 0;
      this.searchData = [];
      this.$emit("close", updatedCardInfo);
    },
    showDataVal() {
      this.cardData = this.cardV.name;
      this.setData = this.cardV.set;
    },
    setSelectedCard(index: number) {
      this.selectedCardIndex = index;
    },
  },
});
