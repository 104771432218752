






































import Vue from "vue";
import type { PropType } from "vue";
import { DisplayCard } from "../helpers/common";

export default Vue.extend({
  name: "CardSlider",
  props: {
    sliderData: Array as PropType<DisplayCard[]>,
  },
  data() {
    return {
      arrow: true,
      arrowHover: true,
      al: {
        hasGrayscale: true,
        itemsToShow: 4,
      },
    };
  },
  methods: {
    setSelected(selected: number) {
      this.$emit("set-selected", selected);
    },
  },
});
